import React from 'react';
import './Navbar.css';
import { Row, Col, Button, Drawer, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { DataContext } from '../../Context/Data';

class Navbar extends React.Component {
    state = {
        visible: false,
    }

    handleDrawer = () => {
        this.setState({
            visible: !this.state.visible,
        })
    }

    render() {
        const begNames = [
            {
                URL: "",
                NavText: "Home",
            },
        ];
        const endNames = [
            {
                URL: 'contact-me',
                NavText: "Contact Me"
            }
        ]
        return (
        <DataContext.Consumer>
            {
                data => {
                const menu = (
                    <Menu>
                    <Menu.Item>
                      <a href="https://book.aumthenticus.com/live-classes">
                        Live Classes
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a href="https://book.aumthenticus.com/on-demand-classes">
                        On Demand Videos
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a href="https://book.aumthenticus.com/memberships">
                        Memberships
                      </a>
                    </Menu.Item>
                  </Menu>
                )
                const allLinks = [...begNames, ...data.websiteData?.Pages, ...endNames];
                return (
                <div>
                    <Row justify="space-between" align="middle" className="Navbar-Large">
                        <Col>
                        <Row gutter={[10,10]}>
                            <Col>
                                <Link to="/">
                                <img className="Navbar-Logo-Image" alt="Aumthenticus" src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fvictoria%2Flogo.png?alt=media&token=4136ed0d-d078-43e1-8a54-b7d32fe6a657" />
                                </Link>
                            </Col>
                            <Col>
                            <Link to="/">
                                <div className="Navbar-Logo-Div">
                                    <h2 className="Landing-Custom-Section-Title-Navbar">AUMthenticus - Undefined and Infinite. I AM</h2>
                                </div>
                            </Link>
                            </Col>
                        </Row>
                        </Col>
                        <Col>
                        <Row gutter={[10,0]}>
                        {
                            allLinks.map((obj, index) => {
                                return (
                                    <Col>
                                    <div className={`${index !== allLinks.length-1 ? "Navbar-Vertical-Line": ""}`}>
                                        <Link to={`/${obj.URL}`}>
                                            <p className="Navbar-Link-Text">{obj.NavText}</p>
                                        </Link>
                                    </div>
                                    </Col>
                                )
                            })
                        }
                        </Row>
                        </Col>
                        <Col>
                            <Dropdown overlay={menu}>
                                <Button className="Navbar-Button">Book a Class</Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row justify="space-between" align="middle" className="Navbar-Small">
                        <Col>
                            <Link to="/">
                            <img className="Navbar-Logo-Image" alt="Aumthenticus" src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fvictoria%2Flogo.png?alt=media&token=4136ed0d-d078-43e1-8a54-b7d32fe6a657" />
                            </Link>
                        </Col>
                        <Col>
                            <Button icon={<MenuOutlined />} onClick={this.handleDrawer} size="large" />
                        </Col>
                    </Row>
                    <Drawer
                    visible={this.state.visible}
                    onClose={this.handleDrawer}
                    width={300}
                    >
                        <Link to="/">
                        <div className="Navbar-Logo-Div">
                        <h2 className="Landing-Custom-Section-Title">AUMthenticus - Undefined and Infinite. I AM</h2>
                        </div>
                        </Link>

                        {
                            allLinks.map((obj, index) => {
                                return (
                                    <div>
                                        <Link to={obj.URL}>
                                            <p className="Navbar-Link-Text-Small">{obj.NavText}</p>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                        <div>
                        <Row justify="center">
                        <Col>
                            <Dropdown overlay={menu}>
                                <Button className="Navbar-Button">Book a Class</Button>
                            </Dropdown>
                        </Col>
                        </Row>
                        </div>
                    </Drawer>
                </div>
                )
                }
            }
        </DataContext.Consumer>
        )
    }
}

export default Navbar;