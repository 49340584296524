import React from 'react';
import './Footer.css';
import { Row, Col, Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataContext } from '../../Context/Data';
import {
    faFacebook,
    faInstagramSquare,
    faTwitter,
    faLinkedin,
    fab,
} from "@fortawesome/free-brands-svg-icons";
import { SocialIcon } from "react-social-icons";

class Footer extends React.Component {
    render() {
        const begNames = [
            {
                URL: "",
                NavText: "Home",
            },
        ];
        const endNames = [
            {
                URL: 'contact-me',
                NavText: "Contact Me"
            }
        ]
        return (
        <DataContext.Consumer>
        {
            data => {
            const menu = (
                <Menu>
                <Menu.Item>
                  <a href="https://book.aumthenticus.com/live-classes">
                    Live Classes
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href="https://book.aumthenticus.com/on-demand-classes">
                    On Demand Videos
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href="https://book.aumthenticus.com/memberships">
                    Memberships
                  </a>
                </Menu.Item>
              </Menu>
            )
            const linkNames = [...begNames, ...data?.websiteData?.Pages, ...endNames]
                return (
            <div className="Footer-Div">
                <Row justify="space-between" align="middle" className="Footer-Row">
                    <Col>
                    <Row gutter={[10,0]}>
                    {
                        linkNames.map((obj, index) => {
                            return (
                                <Col>
                                <div className={`${index !== linkNames.length-1 ? "Footer-Vertical-Line": ""}`}>
                                    <Link to={obj.URL}>
                                        <p className="Footer-Link-Text">{obj.NavText}</p>
                                    </Link>
                                </div>
                                </Col>
                            )
                        })
                    }
                    </Row>
                    </Col>
                    <Col className="Footer-Button-Col">
                        <Dropdown overlay={menu}>
                        <Button className="Navbar-Button-Inverted">Book a Class</Button>
                        </Dropdown>
                    </Col>
                    <Col>
                    <div className="Footer-Follow-Div">
                        <h2 className="Footer-Follow">Follow us on:</h2>
                        <Row gutter={[8,0]}>
                            {
                                data.webTemplate?.SocialMedia?.map((sm) => {
                                    return (
                                    <Col>
                                    <SocialIcon url={sm} />
                                    </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    </Col>
                </Row>
            </div>
                    )
                }
            }
        </DataContext.Consumer>
        )
    }
}

export default Footer;