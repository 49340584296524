import React from 'react';
import './TextPage.css';
import { Navbar, Footer } from '../../Components';
import { Row, Col } from 'antd';
import DOMPurify from 'dompurify';

class TextPage extends React.Component {
    state = {
        VideoControls: false,
    }

    componentDidMount() {
        window.scrollTo(0,0);
        try {
            const promise = document.querySelector("video").play();
            if (promise !== undefined) {
                promise.catch(error => {
                    // Auto Play Prevented
                    this.setState({
                        VideoControls: true,
                    })
                }).then(() => {
                    // Auto-play started
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div>
                <div className="TextPage-Div">
                <Navbar />
                </div>
                <div className="TextPage-Content-Div">
                <div className="TextPage-Video-Div">
                    {
                        this.state.VideoControls ?
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop playsInline>
                            <source src={this.props.data?.VideoBanner}></source>
                        </video>
                        :
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop>
                            <source src={this.props.data?.VideoBanner}></source>
                        </video>
                    }
                </div>
                <Row justify="center" className="TextPage-Row">
                    <Col xs={23}>
                        <div className="TextPage-Text-Div">
                            <h2 className="TextPage-Title">
                            {this.props.data?.Title}
                            </h2>
                            <div className="TextPage-Paragraph">
                                <div
                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.data?.Text) }}
                                />
                            </div>
                        </div>
                        <Row gutter={[10,10]}>
                            {
                                this.props.data?.Gallery ?
                                this.props.data?.Gallery?.map((obj) => {
                                    return (
                                        <Col xs={24} sm={8}>
                                            <img className="TextPage-Gallery-Image" src={obj.SRC} alt={obj.ALT} />
                                        </Col>
                                    )
                                })
                                :
                                null
                            }
                        </Row>
                    </Col>
                </Row>
                <div className="TextPage-Video-Div">
                    {
                        this.props.data?.VideoBanner2 && String(this.props.VideoBanner2).length > 0 ?
                        <div>
                        {
                            this.state.VideoControls ?
                            <video className="Landing-Video" id="Banner-Video" autoPlay muted loop playsInline>
                                <source src={this.props.data?.VideoBanner2}></source>
                            </video>
                            :
                            <video className="Landing-Video" id="Banner-Video" autoPlay muted loop>
                                <source src={this.props.data?.VideoBanner2}></source>
                            </video>
                        }
                        </div>
                        :
                        null
                    }
                </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default TextPage;