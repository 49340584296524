import React from 'react';
import './FeatureCard.css';

class FeatureCard extends React.Component {
    render() {
        return (
            <div className="FeatureCard-Div">
                <img className="FeatureCard-Image" src={this.props.Image} alt={this.props.Alt} />
                <h2 className="FeatureCard-Title">{this.props.Title}</h2>
                <p className="FeatureCard-Description">{this.props.Description}</p>
                <h3 className="FeatureCard-Subtitle">{this.props.Subtitle}</h3>
            </div>
        )
    }
}

export default FeatureCard;