import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { DataProvider, DataContext } from './Context/Data';
import React from 'react';
import {
  Landing,
  TextPage,
  Contact,
} from './Pages';

class App extends React.Component{
  render() {
    return (
      <DataProvider>
        <Router>
          <Switch>
          <DataContext.Consumer>
            {
              data => (
                <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/contact-me" component={Contact} />
                {
                  data.websiteData?.Pages?.map((page) => (
                    <Route exact path={`/${page.URL}`} component={() => <TextPage data={page} />} />
                  ))
                }
                </Switch>
              )
            }
          </DataContext.Consumer>
          </Switch>
        </Router>
      </DataProvider>
    );
  }
}

export default App;
