import React from 'react';
import './Reviews.css';
import { Carousel, Row, Col } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

class Reviews extends React.Component {
    render() {
        return (
            <Carousel arrows nextArrow={<ArrowRightOutlined />} prevArrow={<ArrowLeftOutlined />}>
                {
                    this.props.Reviews?.map((review) => {
                        return (
                            <div className="Review-Container">
                                <Row justify="center" align="middle" gutter={[10,10]}>
                                    <Col xs={20} sm={10} className="Review-Text">
                                    <div className="Review-Text-Left">
                                    <p>"{review.Description}" - {review.Reviewer}</p>
                                    </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                    <img className="Reviews-Image" src={review.Image} alt={review.Alt} />
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }
            </Carousel>
        )
    }
}

export default Reviews;