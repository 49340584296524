import React from 'react';
import { Navbar, FeatureCard, Reviews, Footer } from '../../Components';
import './Landing.css';
import { Row, Col } from 'antd';
import { DataContext } from '../../Context/Data';

class Landing extends React.Component {
    state = {
        Email: '',
        VideoControls: false,
    }

    componentDidMount() {
        try {
            const promise = document.querySelector("video").play();
            if (promise !== undefined) {
                promise.catch(error => {
                    // Auto Play Prevented
                    this.setState({
                        VideoControls: true,
                    })
                }).then(() => {
                    // Auto-play started
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
        <DataContext.Consumer>
            {
                data => (
            <div>
                <div className="Landing-Div">
                <Navbar />
                </div>
                <div className="Landing-Content-Div">
                <div className="Landing-Video-Div">
                    {
                        /*
                        <img className="Landing-Video" src="https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fvictoria%2Fpexels-troy-squillaci-2521620.jpg?alt=media&token=069817da-9259-4d8d-a292-819f8039f937" />
                        */
                    }
                    {
                        this.state.VideoControls ?
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop playsInline>
                            <source src={data.websiteData?.LandingVideo}></source>
                        </video>
                        :
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop>
                            <source src={data.websiteData?.LandingVideo}></source>
                        </video>
                    }
                </div>
                <Row justify="center">
                    <Col xs={23} sm={20}>
                        <h2 className="Landing-Custom-Section-Title">AUMthenticus - Undefined and Infinite. I AM</h2>
                        <p className="Landing-Custom-Section-Paragraph">
                        AUMthenticus invites you to embark on a journey within by incorporating deeper aspects of yoga, breathwork and meditation to ignite transformation. Connecting your mind with the innate wisdom of your body will inspire clarity, creativity and empowerment. A deeper connection with yourself will guide you to embody that authentic, undefined and infinite I AM.
                        </p>
                    </Col>
                </Row>
                <Row justify="space-between" align="stretch" gutter={[30,30]}>
                    {
                        data.websiteData?.FeatureCards?.map((card) => {
                            return (
                                <Col xs={24} sm={8}>
                                <FeatureCard Image={card.Image} Title={card.Title} Description={card.Description} Subtitle={card.Subtitle} />
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row className="Landing-Reviews">
                    <Col xs={24}>
                        <Reviews Reviews={data.websiteData?.Reviews} />
                    </Col>
                </Row>
                </div>
                <Footer />
            </div>

            )
        }
        </DataContext.Consumer>
        )
    }
}

export default Landing;