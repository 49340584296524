import React, { useContext, useState, useEffect } from 'react';
import { ENVIRONMENT, UID } from '../constants';
import axios from 'axios';
import { Spin, Row, Col } from 'antd';

export const DataContext = React.createContext();

export function useData() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [profile, setProfileData] = useState(false);
    const [websiteData, setWebsiteData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [webTemplate, setWebTemplate] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const payload = {
                UID: UID,
            }
            // Send Axios Post Request to get Data
            if (loading === true) {
                const request = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/get-custom-website-data-uid`, payload);
                setWebsiteData(request.data.websiteData);
                setProfileData(request.data.profile);
                setWebTemplate(request.data.webTemplate);
            }
            // Set the Data
            setLoading(false);
        }
        getData();
    })

    const value = {
        profile,
        websiteData,
        webTemplate,
    };

    return (
        <DataContext.Provider value={value}>
            {
                loading ?
                <Row justify='center' align='middle' style={{ height: '100vh' }}>
                    <Col>
                        <Spin size='large' />
                    </Col>
                </Row>
                :
                children
            }
        </DataContext.Provider>
    )
}