import React from 'react';
import { Navbar, Footer } from '../../Components';
import { Row, Col, Input, Button, message, notification } from 'antd';
import { DataContext } from '../../Context/Data';
import './Contact.css';
import axios from 'axios';
import { ENVIRONMENT } from '../../constants';

class Contact extends React.Component {
    state = {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Message: "",
        VideoControls: false,
    }

    componentDidMount() {
        try {
            const promise = document.querySelector("video").play();
            if (promise !== undefined) {
                promise.catch(error => {
                    // Auto Play Prevented
                    this.setState({
                        VideoControls: true,
                    })
                }).then(() => {
                    // Auto-play started
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
        }
        message.error("Please enter a valid email.")
        return (false)
    }

    validateTextInput = (text, name) => {
        if (String(text).length === 0) {
            message.error(`Please enter a ${name}.`)
            return false;
        } else {
            return true;
        }
    }

    submitContact = async (trainerEmail) => {
        const emailValidate = this.validateEmail(this.state.Email);
        if (!emailValidate) return;
        const nameValidate = this.validateTextInput(this.state.FirstName);
        if (!nameValidate) return;
        const data = {
            name: `${this.state.FirstName} ${this.state.LastName}`,
            email: this.state.Email,
            phone: this.state.PhoneNumber,
            message: this.state.Message,
            TrainerEmail: trainerEmail,
        }
        const sendEmail = await axios.post(`${ENVIRONMENT.BACKEND_URL}/custom-website/contact`, data);
        if (sendEmail.data.error) {
            notification.error({
                message: "Something went wrong!",
                description: `Sorry we couldn't send you message, please send me an email at ${trainerEmail}`,
                duration: 0,
            })
        } else {
            notification.success({
                message: "Success",
                description: "Thanks for your message, I'll be in contact with you soon.",
            })
        }
    }


    render() {
        return (
            <DataContext.Consumer>
                {
                    data => (

            <div>
                <div className="TextPage-Div">
                <Navbar />
                </div>
                <div className="TextPage-Content-Div">
                <div className="TextPage-Video-Div">
                    {
                        this.state.VideoControls ?
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop playsInline>
                            <source src={data?.websiteData?.ContactVideo}></source>
                        </video>
                        :
                        <video className="Landing-Video" id="Banner-Video" autoPlay muted loop>
                            <source src={data?.websiteData?.ContactVideo}></source>
                        </video>
                    }
                </div>
                <Row justify="center" className="TextPage-Row">
                    <Col xs={23}>
                        <h1>Contact Me</h1>
                        <Row gutter={[20,20]}>
                            <Col xs={24} sm={12}>
                                <h3>First Name</h3>
                                <Input size="large" value={this.state.FirstName} onChange={(e) => this.handleChange("FirstName", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <h3>Last Name</h3>
                                <Input size="large" value={this.state.LastName} onChange={(e) => this.handleChange("LastName", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <h3>Email</h3>
                                <Input size="large" value={this.state.Email} onChange={(e) => this.handleChange("Email", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <h3>Phone Number</h3>
                                <Input size="large" value={this.state.PhoneNumber} onChange={(e) => this.handleChange("PhoneNumber", e.target.value)} />
                            </Col>
                            <Col xs={24}>
                                <h3>Message</h3>
                                <Input.TextArea autoSize={{minRows:4}} value={this.state.Message} onChange={(e) => this.handleChange("Message", e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="Contact-Submit">
                            <Col xs={24}>
                                <Button onClick={() => this.submitContact(data.profile?.Email)} className="Navbar-Button">Submit</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </div>
                <Footer />
            </div>

            )
        }
        </DataContext.Consumer>
        )
    }
}

export default Contact;